import axios from 'axios'
import React, { Component } from 'react'
import { Field, Form } from 'react-final-form'
import { ReactSVG } from 'react-svg'
import { strapi, trFunction } from 'utils/functions'
var hasher = require('wordpress-hash-node')

// TODO: login returns to previous page

class LostPasswordForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      postedEmail: null,
      error: null,
      loading: false,
    }
  }

  render() {
    const locale = this.props.data.site.siteMetadata.locale
    const translations = this.props.data.translations
    const tr = (code) => trFunction(code, translations, locale)

    const onSubmit = (values) => {
      this.setState({ error: null, loading: true })

      // check if the email exists
      let urlCheckEmail = strapi.url
      urlCheckEmail += `/users?email=${values.email}`

      axios({
        method: 'get',
        url: urlCheckEmail,
        headers: {
          Authorization: `Bearer ${strapi.token}`,
        },
      }).then((response) => {
        if (response.data.length < 1) {
          // noEmail
          this.setState({ error: '' })
          setTimeout(() => {
            this.setState({
              postedEmail: values.email,
              error: 'noEmail',
              loading: false,
            })
          }, 500)
        } else {
          // make token
          const token = String(
            Math.floor(Math.random() * 899999999999 + 100000000000),
          )
          // post email + token to user-password-tokens
          const postTokenUrl = `${strapi.url}/user-password-tokens`
          axios({
            method: 'post',
            url: postTokenUrl,
            headers: {
              Authorization: `Bearer ${strapi.token}`,
            },
            data: {
              email: values.email,
              token,
            },
          }).then((response) => {
            // send email
            const postObj = {
              // form: JSON.stringify(values, 0, 2),
              form: { email: values.email, token },
              locale,
              translations,
            }
            const sendEmailUrl =
              'https://stellar.lanternbox.com/email/emails/pwRequest/email.php'
            const that = this
            axios
              .post(sendEmailUrl, postObj)
              .then(function (response) {
                that.setState({
                  postedEmail: values.email,
                  error: 'success',
                  loading: false,
                })
              })
              .catch(function (error) {})
          })
        }
      })
    }

    const loadingClass = this.state.loading ? 'loading' : ''
    return (
      <div className="woocomerce-form">
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <p className="form-row form-row-wide">
                <Field
                  name="email"
                  component="input"
                  type="email"
                  placeholder={tr('FORM_ITEM_EMAIL')}
                  required
                />
              </p>

              <div className={`submit-wrap full ${loadingClass}`}>
                <span className="loader">
                  <ReactSVG src="/images/loader.svg" />
                </span>
                <input
                  className="woocommerce-Button button"
                  type="submit"
                  value={tr('FORM_ITEM_RESET_PASSWORD')}
                  disabled={this.state.loading}
                />
              </div>
            </form>
          )}
        />
        <ul className="error-holder">
          <li className={this.state.error === 'noEmail' ? 'show' : ''}>
            <strong>{tr('FORM_ITEM_ERROR')}</strong>
            <span className="email">{this.state.postedEmail}</span>
            {tr('FORM_REQUEST_PASSWORD_ERROR_EMAIL_NOT_FOUND')}
          </li>
          <li className={this.state.error === 'success' ? 'show' : ''}>
            {tr('FORM_REQUEST_PASSWORD_SUCCESS')}
            <span className="email">{this.state.postedEmail}</span>
            {tr('FORM_REQUEST_PASSWORD_SUCCESS_2')}
          </li>
        </ul>
      </div>
    )
  }
}

export default LostPasswordForm
